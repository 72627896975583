import React from "react"
import { graphql } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
import JumboImage from "../components/JumboImage"
import People from "../components/People"
import Navbar2 from "../components/Navbar2"
import { Container, Row, Col } from "reactstrap"
import { MediaQueries } from "../utils/responsive"

const StyledHomePage = styled.div`
  .mainContent {
    @media ${MediaQueries.mobileUp} {
      margin-top: 85vh;
    }
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 1rem 0;
    .line {
      height: 2px;
      background-color: ${props => props.theme.colors.white};
      flex: 1;
    }
    h2 {
      font-size: 2rem;
      padding: 0 1rem;
      margin: 0;
      font-family: "Poppins";
      font-weight: 400;
      @media ${MediaQueries.mobileUp} {
        font-size: 2.4rem;
      }
    }
  }
  .formattedList {
    h2 {
      font-size: 2.4rem;
      padding: 2rem 0;
      margin: 0;
      @media ${MediaQueries.mobileUp} {
        font-size: 2.8rem;
      }
      li {
        font-family: "IBM Plex Serif";
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

const IndexPage = props => {
  const { t, i18n } = useTranslation("home")
  const language = i18n.language
  // const alternateLinks = useContext(AlternateLinksContext)

  return (
    <Layout alternateLink={props.alternateLinks}>
      <StyledHomePage>
        <SEO title="Justanatomy.com | Home" />
        {/* Main image */}
        <Container fluid className="JumboImage text-center px-0">
          <JumboImage />
        </Container>
        <Container fluid className="SecondNav">
          <Navbar2 {...props} />
        </Container>
        {/* Content */}
        <Container className="mainContent">
          <Row>
            <Col xs={12} className="About" id={t(`common:menu.${1}.slug`)}>
              <SanityBlockContent
                blocks={props.data.sanityHomePage.about[language]}
                className="formattedList"
              />
            </Col>
            <Col xs={12} className="divider">
              <div className="line"></div>
              <h2>{t("about")}</h2>
              <div className="line"></div>
            </Col>
          </Row>
          <Row>
            <People />
          </Row>

          <Row>
            <Col xs={12} className="divider" id={t(`common:menu.${2}.slug`)}>
              <div className="line"></div>
              <h2>{t("people")}</h2>
              <div className="line"></div>
            </Col>
            <Col xs={12} className="Education" style={{ paddingTop: "40px" }}>
              <SanityBlockContent
                blocks={props.data.sanityHomePage.education[language]}
                className="formattedList"
              />
            </Col>
            <Col xs={12} className="divider">
              <div className="line"></div>
              <h2>{t("education")}</h2>
              <div className="line"></div>
            </Col>
            <Col
              xs={12}
              className="Services"
              style={{ paddingTop: "40px" }}
              id={t(`common:menu.${3}.slug`)}
            >
              <SanityBlockContent
                blocks={props.data.sanityHomePage.services[language]}
                className="formattedList"
              />
            </Col>
            <Col xs={12} className="divider">
              <div className="line"></div>
              <h2>{t("services")}</h2>
              <div className="line"></div>
            </Col>
          </Row>
        </Container>
      </StyledHomePage>
    </Layout>
  )
}

// TODO - add query for Homepage content
// TODO - add query for People
export const query = graphql`
  query Homepage {
    sanityHomePage {
      about: _rawAbout
      education: _rawEducation
      services: _rawServices
    }
  }
`

export default IndexPage
