import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"
import { useTranslation } from "react-i18next"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Col, Row } from "reactstrap"
import styled from "styled-components"

const StyledPeopleCol = styled(Col)`
  .logo-image {
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    &:hover {
      filter: brightness(0.8);
    }
  }
  .logo-link {
    display: inline-block;
    margin-right: 1.5rem;
  }
`

const People = () => {
  const { i18n } = useTranslation("home")
  const language = i18n.language
  const data = useStaticQuery(graphql`
    query {
      people: allSanityPeople {
        edges {
          node {
            _id
            title
            intro: _rawIntro
            position: _rawPosition
            picture {
              asset {
                gatsbyImageData
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const externalLinks = [
    // {
    //   rg: "https://www.researchgate.net/profile/Kalman-Czeibert",
    //   li: "https://www.linkedin.com/in/kalman-czeibert",
    // },
    {
      rg: "https://www.researchgate.net/profile/Ors-Petnehazy-Dvm",
      li: "https://www.linkedin.com/in/ors-petnehazy-73b0567/",
    },
  ]
  return data.people.edges.map((p, i) => (
    <StyledPeopleCol xs={12} key={p.node._id}>
      <Row>
        <Col xs={{ size: 12, order: 2 }} md={{ size: 9, order: 1 }}>
          <h2 className="mt-5 mt-md-0">{p.node.title}</h2>
          <SanityBlockContent
            key={p.node._id}
            blocks={p.node.intro[language]}
            projectId={process.env.SANITY_PROJECTID}
            dataset={process.env.SANITY_DATASET}
            imageOptions={{ w: 200, h: 100, fit: "max" }}
          />
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 3, order: 2 }}>
          <GatsbyImage
            style={{ border: "2px solid white" }}
            image={getImage(p.node.picture?.asset)}
            alt={p.node.title}
          />
        </Col>
        <Col xs={{ size: 12, order: 3 }}>
          <p>Summary of scientific background:</p>

          <a
            href={externalLinks[i].rg}
            alt="ResearchGate profile"
            className="logo-link"
          >
            <StaticImage
              width={50}
              height={50}
              src="../../images/RG_logo.jpeg"
              alt="ResearchGate profile"
              className="logo-image"
            />
          </a>

          <a
            href={externalLinks[i].li}
            alt="Linkedin profile"
            className="logo-link"
          >
            <StaticImage
              width={50}
              height={50}
              src="../../images/LI_logo.png"
              alt="LinkedIn profile"
              className="logo-image"
              backgroundColor="rgba(255,255,255,1)"
            />
          </a>
        </Col>
      </Row>
      <Row>
        {i === 0 ? (
          <Col xs={{ size: 12, order: 3 }} className="divider mb-5">
            <div className="line"></div>
            <div className="line"></div>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </StyledPeopleCol>
  ))
}

export default People
