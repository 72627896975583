import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MediaQueries } from "../../utils/responsive"
import "@fontsource/ibm-plex-sans/700.css"
import "@fontsource/poppins/100.css"
import "@fontsource/poppins/800.css"

const StyledHolder = styled.div`
  position: relative;
  @media ${MediaQueries.mobileUp} {
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const StyledTitle = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2.4rem;
  text-shadow: 0px 0px 5px #000000aa;
  span {
    font-family: "IBM Plex Sans";
    font-size: 4.8rem;
    font-weight: 700;
  }
  @media ${MediaQueries.mobileUp} {
    font-size: 3rem;
    font-weight: 400;
    span {
      font-family: "IBM Plex Sans";
      font-size: 7.6rem;
      font-weight: 700;
    }
  }
`

const StyledImg = styled(GatsbyImage)`
  height: 250px;
  object-fit: cover;
  @media ${MediaQueries.mobileUp} {
    height: 90vh;
  }
`

const JumboImage = props => {
  const data = useStaticQuery(graphql`
    query JumboPictureQuery {
      sanityHomePage {
        picture1 {
          asset {
            gatsbyImageData
          }
        }
        picture2 {
          asset {
            gatsbyImageData
          }
        }
        picture3 {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  `)

  const getRandomInt = max => {
    return Math.floor(Math.random() * max)
  }
  let myPicture

  switch (getRandomInt(3)) {
    case 0:
      myPicture = getImage(data.sanityHomePage.picture1?.asset)
      break
    case 1:
      myPicture = getImage(data.sanityHomePage.picture2?.asset)
      break
    case 2:
      myPicture = getImage(data.sanityHomePage.picture3?.asset)
      break
    default:
      myPicture = getImage(data.sanityHomePage.picture1?.asset)
  }

  return (
    <StyledHolder>
      <StyledImg image={myPicture} alt="Main_image" />
      <StyledTitle>
        <span className="text-uppercase">Just</span>
        <br />
        Anatomy
      </StyledTitle>
    </StyledHolder>
  )
}

export default JumboImage
